// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getDatabase} from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCgT6Z5qUMD4wSUcXVQOflanxnAejnoHXA",
  authDomain: "hotseat-9e873.firebaseapp.com",
  databaseURL: "https://hotseat-9e873-default-rtdb.firebaseio.com",
  projectId: "hotseat-9e873",
  storageBucket: "hotseat-9e873.appspot.com",
  messagingSenderId: "768732717921",
  appId: "1:768732717921:web:a50fb025fe8016726c1b20",
  measurementId: "G-Y89FVM0XJ7"
};


// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const database = getDatabase(firebase);
const analytics = getAnalytics(firebase);

export default database;
