import logo from './logo.svg';
import './App.css';
import GameShell from './game_shell.js';
import Landing from './Home/landing.js';
import LobbyForm from './Lobby/lobby_form.js';
import {Routes, Route} from 'react-router-dom';

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Landing/>}></Route>
        <Route path='gameshell' element={<GameShell/>}></Route>
        <Route path="/games/:gameId/:userId" element={<GameShell />} />
        <Route path='form' element={<LobbyForm/>}/>
      </Routes>
    </div>
  );
}

export default App;

// <Hotseat game_id={"jester"} user_id={1} started={false}/>
