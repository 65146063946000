let questions = {
  "Family" : [
    "How many siblings do you have?",
    "Who is your favorite parent?",
    "Who are you more like, your mom or your dad?",
    "Would you want an identical twin, if you could chose to have one?"
  ],
  "Gifts" : [
    "What is your favorite gift you've ever recieved?",
    "What gift would you most want to give yourself for your next birthday?",
    "What is the worst gift you've ever recieved?",
    "What is your go-to gift to give to people?"
  ],
  "Animals" : [
    "What is your spirit animal?",
    "If you could have any animal as a pet, what would it be?",
    "Do you have any pets?",
    "Cats or dogs?"
  ],
  "Hobbies" : [
    "What is your favorite outdoor activity?",
    "What is a skill or interest you admire in others?",
    "What are you doing this friday night?",
    "Did you pick up any pandemic hobbies?"
  ],
  "Travel" : [
    "What is your favorite place you've visited?",
    "Place you've visited that was the most overrated",
    "Have you ever taken a spontaneous trip?",
    "Travel location that is at the top of your bucket list"
  ],
  "Current Thing" : [
    "What was the last meal you ate?",
    "What is your current nighttime routine?",
    "What is the last thing you bought online?",
    "What TV show are you currently watching?",
    "What purchase are you currently saving money for?"
  ],
  "Holidays" : [
    "What was your favorite holiday as a child?",
    "Way you spent the last holiday",
    "What is the most overrated holiday?",
    "What is the best holiday for food?",
    "Did you believe in santa claus as a kid?"
  ],
  "Culture" : [
    "Thing you most love sharing about your culture",
    "What are the most important values you inherited from your parents?",
    "What is your favorite thing about the place you grew up?",
    "What values do you most admire?"
  ],
  "Likes/Dislikes" : [
    "If you fed this to me, I'd throw up",
    "Do you prefer the city, suburbs, or country?",
    "What genre of music do you most love and most hate?"
  ],
  "Pop Culture" : [
    "Who is your favorite disney princess?",
    "What was the last movie you saw?",
    "Star wars or star trek?",
    "Who was your first celebrity crush?",
    "If you had to listen to one song for the rest of your life what would it be?"
  ],
  "Hopes & Dreams" : [
    "What did you want to be when you grew up at the age of 10?",
    "What is your dream car or vehicle?",
    "What do you want the epithet on your tombstone to read?",
    "Characteristics of your dream home"
  ],
  "Best" : [
    "Who is your best friend?",
    "What is the best restaurant in your city?",
    "What is your favorite song?",
    "What is the best movie ever made?"
  ],
  "Random" : [
    "Have you ever gone three full days without eating?",
    "Have you ever broken a bone?",
    "What are your financial goals?",
    "What was your first job?"
  ],
  "Food" : [
    "PB & J or Mac and Cheese?",
    "Favorite ice cream flavor",
    "If you could only eat one food for the rest of your life what would it be?",
    "Do you have any food allergies?"
  ],

};

export default questions;
