import * as React from 'react';

import {useEffect, useState} from "react";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { grey } from '@mui/material/colors';

import ChoseQuestion from './chose_question.js';
import ChoseHotseatUser from './chose_hotseat_user.js';
import ManageGame from './manage_game.js';
import questions from './questions.js';

import fire from '../images/fire.gif';
import crown from '../images/crown.png';
import crown_bison from '../images/crown_bison-modified.png';

import Circle from './circle2.js';

import useColorChange from 'use-color-change';

import './style.css';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push, remove} from "firebase/database";

const color = grey[500];

// TODO: control the view based on database values, if someone comes in late do something?
export default function GameContainer(props) {
  const [category, setCategory] = useState("");
  const [categoryChoices, setCategoryChoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [uopen, setUOpen] = useState(false);
  const [manageOpen, setManageOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleUClose = () => setUOpen(false);
  const handleManageClose = () => setManageOpen(false);
  const [question, setQuestion] = useState("");
  const [hotseatUser, setHotseatUser] = useState("");
  const [askingUser, setAskingUser] = useState("");
  const [wipe, setWipe] = useState(false);
  const [partyMode, setPartyMode] = useState(false);

  const colorStyle = useColorChange(question, {
      higher: "orange",
      lower: "orange",
      duration: 10000
    });

    const colorStyle2 = useColorChange(category, {
        higher: "orange",
        lower: "orange",
        duration: 10000
      });

  // Set state
  // quesiton, category, hotseat, asking user
  let disabled = true;
  if (hotseatUser == props.user_id) {
    disabled = false;
  }

  let pmessage;
  if (partyMode) {
    pmessage = "🎉 You're in party mode 🎉 The person in the hotseat will be shuffled with each question."
  }


  // Get State
  useEffect(() => {
    const stateref = ref(database, 'games/' + props.game_id +"/state/");
    onValue(stateref, (snapshot) => {
      const data = snapshot.val();
      setCategory(data.category);
      setCategoryChoices(data.categoryChoices);
      setHotseatUser(data.hotseatU);
      setAskingUser(data.askingUser);
      setQuestion(data.question);
      setPartyMode(data.partyMode);
      if (data.askingUser == props.user_id) {
          setOpen(true);
      } else {
        setOpen(false);
      }
    });
    return () => off(stateref)
  }, []);


  const changeHotseat = () => {
    setUOpen(true);
    let three_categories = getThreeCategories(questions);
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      hotseatU: hotseatUser,
      question: question,
      category: category,
      askingUser: askingUser,
      categoryChoices: three_categories,
      partyMode: false,
    });
  }

  const manageRoom = () => {
    setManageOpen(true);
  }

  const clickPartyMode= () => {
    setPartyMode(true);
    let hu = pickRandomUser(props.users, hotseatUser, askingUser);
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      hotseatU: hu,
      question: question,
      category: category,
      askingUser: askingUser,
      categoryChoices: categoryChoices,
      partyMode: true,
    });
  }

  const setHotseatU = (u) => {
    set(ref(database, '/games/' + props.game_id + "/state/"), {
      hotseatU: u,
      question: question,
      category: category,
      askingUser: askingUser,
      categoryChoices: categoryChoices,
      partyMode: partyMode,
    });
  }

  const setQ = (q, cat) => {
    set(ref(database, '/games/' + props.game_id +  "/state/"), {
      hotseatU: hotseatUser,
      question: q,
      category: cat,
      askingUser: askingUser,
      categoryChoices: categoryChoices,
      partyMode: partyMode,
    });
  }

  const click = () => {
    let three_categories = getThreeCategories(questions);
    // set asking user as well
    if (partyMode) {
      let array = pick2RandomUsers(props.users, hotseatUser, askingUser);
      let au = array[0];
      let hu = array[1];
      set(ref(database, '/games/' + props.game_id + "/state/"), {
        hotseatU: hu,
        question: "",
        category: "",
        askingUser: au,
        categoryChoices: three_categories,
        partyMode: partyMode,
      });

    } else{
      let au = pickRandomUser(props.users, hotseatUser, askingUser);
      set(ref(database, '/games/' + props.game_id + "/state/"), {
        hotseatU: hotseatUser,
        question: "",
        category: "",
        askingUser: au,
        categoryChoices: three_categories,
        partyMode: partyMode,
      });
    }
  }

// TODO: only show to the user who can chose, pull the categories/etc from the central database
 let circle;
 if (props.users) {
   circle = <Circle users={props.users} hotseatUser={hotseatUser} askingUser={askingUser}/>
 }


  return (
      <div id="bounce1">
      <Grid container spacing={2}>
          <Grid item xs={12} md={2}>
            <Box sx={{mt:4, ml:2, mr: 2}}>
              <Typography variant="h5">
                #{props.game_id} <br/>
                {props.user_id} <br />
              </Typography>
              <br/>
              <br/>
              <br/>
              <br/>

              <Typography variant="subtitle" align="center">
              The person in the hotseat can move you to the next question <br/>
                <br/><Button onClick={click} disabled={disabled} variant="contained">Next Question</Button>
              </Typography>
              <Box sx={{mt:2,}}>
                <Typography variant="subtitle" color="#400400" align="center" sx={{mt:4}}>
                  {pmessage}
                </Typography>
              </Box>
            </Box>
            </Grid>
          <Grid item xs={12} md={10}>
      <Box sx={{mt:3, ml:2}}>
      <Grid container spacing={2}>
      <Grid item xs={12} md={9}>

      <Typography variant="h5" align="center">
        <b>Topic</b><br/> <div style={colorStyle}>{category}</div>
      </Typography>
      <Typography variant="h5" align="center">
        <b>Question</b>
      </Typography>
      <Typography variant="h2" align="center">
        <div style={colorStyle}>{question}</div>
      </Typography>
      <br/>
      <br/>
      <br/>

        {circle}

          <ChoseQuestion categoryChoices={categoryChoices} setQuestion={setQ} open={open} questions={questions} handleClose={handleClose}/>
          <ChoseHotseatUser users={props.users} setUser={setHotseatU} open={uopen} handleClose={handleUClose}/>
          <ManageGame users={props.users} game_id={props.game_id} open={manageOpen} handleClose={handleManageClose}/>

        </Grid>
        <Grid item xs={12} md={3}>
        <Box sx={{display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'}}>
          <img src={crown_bison} width="200"/>
          <br/>
          <br/>
          <b>hotseat</b> {hotseatUser} <br/><img src={fire} width="50" />

          <br/> <br/>
          <b>asking </b>{askingUser}<br/> <img src={crown} width="30" />
          <br/> <br/>
          <Typography variant="h6" align="center">

            <Button onClick={changeHotseat}>Put someone in the hotseat</Button>
            <Button onClick={clickPartyMode} disabled={partyMode}> 🎉 Party Mode</Button>
            <Button onClick={manageRoom}> Manage Room</Button>
          </Typography>

        </Box>
        </Grid>

        </Grid>
        </Box>
        </Grid>
        </Grid>
      </div>
  );
}

function getThreeCategories(questions) {
    return shuffle(Object.keys(questions)).slice(0, 3);
};

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}


function pickRandomUser(users, hotseatU, askingU) {
  // pick a random user but not the hotseat user
  let remainingArr = users.filter(name => name != hotseatU);
  if (users.length > 2) {
    let remainingArr2 = remainingArr.filter(name => name != askingU);
    return shuffle(remainingArr2)[0];

  } else {
    return shuffle(remainingArr)[0];
  }
}

function pick2RandomUsers(users, hotseatU, askingU) {
  // pick a random user but not the hotseat user
  let remainingArr1 = users.filter(name => name != hotseatU);
  let au = shuffle(remainingArr1)[0];
  let remainingArr2 = users.filter(name => name != askingU);
  let remainingArr3 = remainingArr2.filter(name => name != au);
  let hu = shuffle(remainingArr3)[0];
  return [au, hu];
}
