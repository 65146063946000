import * as React from 'react';

import {useEffect, useState} from "react";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import database from '../firebase_database.js';
import {getDatabase, ref, set, onValue, off, push, remove} from "firebase/database";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

// TODO: control the view based on database values, if someone comes in late do something?
export default function ManageGame(props) {

  const clickUser = (u) => {
    console.log(u);
    let usersref = ref(database, 'games/' + props.game_id + "/users/" + u);
    remove(usersref).then(() => console.log("Deleted"));
    props.handleClose();
  }


  let show;
  if (props.open) {
    show = props.users.map((u, step) => {
          return (

            <Grid item xs={3} align="center" key={u}>
                <Button onClick={() => clickUser(u)}>
                <Typography color="text.secondary" variant="h5">
                {u}
                </Typography></Button>
            </Grid>

            )
        });
    }


  return (
      <div id="bounce1">
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
        <Box sx={{ ...style, width: 400 }}>
        <Box sx={{mb: 5}}>
        <Typography variant="h5">Click on a user to boot them from the game. They will be able to rejoin the game through the game lobby.</Typography>
        </Box>
          {show}
          </Box>
        </Modal>

      </div>
  );
}
